import React from "react"

export default class Legend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    return (
      <div id="legend" className="row" style={{ padding:"10px",marginBottom:"20px" }}>
        <div style={{ textAlign:"center",padding:"10px",fontSize:"20px",fontWeight:"bold",backgroundColor:"#5a5a5a",color:"white" }}>Legend</div>

        <table className="table table-bordered">
          <tbody>
            <tr>
              <td style={{ textAlign:"center" }}>
                <span style={{ padding:"2px 3px",fontWeight:"bold",color:"white",borderRadius:"4px",marginLeft:"4px",fontSize:"8px",position:"relative",top:"-2px",backgroundColor:"#162635" }}>SF</span>
              </td>
              <td>
                This league is a Superflex league.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <span style={{ padding:"2px 3px",fontWeight:"bold",color:"white",borderRadius:"4px",marginLeft:"4px",fontSize:"8px",position:"relative",top:"-2px",backgroundColor:"#162635" }}>2 QB</span>
              </td>
              <td>
                This league is a 2 QB league.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <span style={{ padding:"2px 3px",fontWeight:"bold",color:"white",borderRadius:"4px",marginLeft:"4px",fontSize:"8px",position:"relative",top:"-2px",backgroundColor:"#162635" }}>TE+</span>
              </td>
              <td>
                This league is a TE Premium league.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>Core</td>
              <td>
                A team's "core" players are those that are valued the highest on that team to make a starting lineup.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>Core %</td>
              <td>
                A team's total core value divided by the highest possible core value (regardless of team).
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>Weekly Avg</td>
              <td>
                A team's average starting rank for that week.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div style={{ borderRadius:"4px",backgroundColor:"#25bdff",margin:"auto",color:"white",fontWeight:"bold",fontSize:"20px",width:"30px",height:"30px" }}>1</div>
              </td>
              <td>
                Your team and your team's rank within the league. Rank is based on team value and has no basis on actual outcomes in your league or current win/loss.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <i className="fas fa-check-circle" style={{ color:"#25bdff",fontSize:"12px",paddingLeft:"2px" }}></i>
              </td>
              <td>
                This user has registered on Dynasty Cheat Sheet. They now know your secret to winning the league.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center",width:"25%" }}>
                <span style={{ color:"green",fontWeight:"bold",fontSize:"10px" }}>1 Available</span>
              </td>
              <td>
                The number of roster spots available on this team
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <span style={{ color:"red",fontWeight:"bold",fontSize:"10px" }}>1 Over</span>
              </td>
              <td>
                The number of roster spots over the roster limit on this team
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div style={{ color:"black",fontWeight:"bold",backgroundColor:"#25bdff15" }}>Player Name</div>
              </td>
              <td>
                This is a team's "core" player
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div className="text-decoration-line-through" style={{ color:"red" }}>Player Name</div>
              </td>
              <td>
                This player is on this team's IR
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"#800080",padding:"1px 2px",borderRadius:"100px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
                  <strong>R</strong>
                </span>
              </td>
              <td>
                This player is currently a Rookie
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"#80008070",padding:"1px 2px",borderRadius:"100px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
                  <strong>2</strong>
                </span>
              </td>
              <td>
                This player is currently in his 2nd year
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"#80008040",padding:"1px 2px",borderRadius:"100px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
                  <strong>3</strong>
                </span>
              </td>
              <td>
                This player is currently in his 3rd year
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"orange",padding:"1px 2px",borderRadius:"4px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
                  <strong>TX</strong>
                </span>
              </td>
              <td>
                This player is on this team's taxi squad
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div style={{ color:"red",padding:"3px 6px",fontSize:"8px" }}>FA</div>
              </td>
              <td>
                This player is a free agent in the NFL
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div className="color-white" style={{ background: "#fbb03b",borderRadius:"100px",padding:"3px 0",fontSize:"10px",margin:"auto",height:"22px",width:"22px" }}>
                  <strong>1</strong>
                </div>
              </td>
              <td>
                This player and their rank for the week are considered an "elite" starter for this team
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div style={{ border: "2px solid #fbb03b",borderRadius:"100px",padding:"2px 1px",fontSize:"9px",margin:"auto",height:"22px",width:"22px" }}>
                  1
                </div>
              </td>
              <td>
                This player and their rank for the week are considered an "elite" starter for this team but the team has a better starter
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div className="color-white" style={{ background: "#25bdff",borderRadius:"100px",padding:"3px 0",fontSize:"10px",margin:"auto",height:"22px",width:"22px" }}>
                  <strong>1</strong>
                </div>
              </td>
              <td>
                This player and their rank for the week are considered a "good" starter for this team
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div style={{ border: "2px solid #25bdff",borderRadius:"100px",padding:"2px 1px",fontSize:"9px",margin:"auto",height:"22px",width:"22px" }}>
                  1
                </div>
              </td>
              <td>
                This player and their rank for the week are considered a "good" starter for this team but the team has a better starter
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>
                <div className="color-white" style={{ background: "red",borderRadius:"100px",padding:"3px 0",fontSize:"10px",margin:"auto",height:"22px",width:"22px" }}>
                  <strong>1</strong>
                </div>
              </td>
              <td>
                This player and their rank for the week are considered a "bad" starter for this team
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>DYNO</td>
              <td>
                The player's current dynasty rank.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>SZN</td>
              <td>
                The player's current seasonal rank.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center",position:"relative" }}>
                <i className="fas fa-angle-double-up fs-10px" style={{ color:"green",left:"0",right:"0" }}></i>
              </td>
              <td>
                This player heavily favors their dynasty rank.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center",position:"relative" }}>
                <i className="fas fa-angle-up fs-10px" style={{ color:"green",left:"0",right:"0" }}></i>
              </td>
              <td>
                This player favors their dynasty rank.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center",position:"relative" }}>
                <i className="fas fa-angle-down fs-10px" style={{ color:"red",left:"0",right:"0" }}></i>
              </td>
              <td>
                This player favors their seasonal rank.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center",position:"relative" }}>
                <i className="fas fa-angle-double-down fs-10px" style={{ color:"red",left:"0",right:"0" }}></i>
              </td>
              <td>
                This player heavily favors their seasonal rank.
              </td>
            </tr>

            <tr>
              <td style={{ textAlign:"center" }}>Value</td>
              <td>
                The player's value, which is determined by a number of things such as age, dynasty rank, seasonal rank and more.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
