import React from "react"

export default class WeeklyStartIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { leagueSize, notStartingOnThisTeam, position, rank, superflex, maxStartersInLeague, offseason } = this.props;
    let eliteStarterIcon = (rank) => {
      return (
        <div className="color-white" style={{ background: "#fbb03b",borderRadius:"100px",padding:"3px 0",fontSize:"10px",margin:"0px 4px" }}>
          <strong>{rank}</strong>
        </div>
      )
    }
    let eliteStartableIcon = (rank) => {
      return (
        <div style={{ border: "2px solid #fbb03b",borderRadius:"100px",padding:"2px 1px",fontSize:"9px",margin:"0px 4px" }}>
          {rank}
        </div>
      )
    }
    let goodStarterIcon = (rank) => {
      return (
        <div className="color-white" style={{ background: "#25bdff",borderRadius:"100px",padding:"3px 0",fontSize:"10px",margin:"0px 4px" }}>
          <strong>{rank}</strong>
        </div>
      )
    }
    let goodStartableIcon = (rank) => {
      return (
        <div style={{ border: "2px solid #25bdff",borderRadius:"100px",padding:"2px 1px",fontSize:"9px",margin:"0px 4px" }}>
          {rank}
        </div>
      )
    }
    let badStarterIcon = (rank) => {
      return (
        <div className="color-white" style={{ background: "#FF0000",borderRadius:"100px",padding:"3px 0",fontSize:"10px",margin:"0px 4px" }}>
          <strong>{rank}</strong>
        </div>
      )
    }
    let notStartable = (rank) => {
      return (
        <div className="fs-12px" style={{ padding:"2px 1px",margin:"0px 4px" }}>
          {rank}
        </div>
      )
    }
    let offseasonIcon = () => {
      return (
        <div className="fs-12px" style={{ padding:"2px 1px",margin:"0px 4px" }}>-</div>
      )
    }
    let rankThreshold = position == "TE" ? 50 : (position == "QB" ? (superflex ? leagueSize : (leagueSize / 2)) : (leagueSize * 2))
    let startable = rank <= (position == "QB" ? (superflex ? (leagueSize * 2) : leagueSize) : maxStartersInLeague)
    let eliteStartable = rank <= rankThreshold

    if (offseason) {
      return offseasonIcon()
    } else {
      if (notStartingOnThisTeam) {
        if (startable) {
          if (eliteStartable) {
            return eliteStartableIcon(rank)
          } else {
            return goodStartableIcon(rank)
          }
        } else {
          return notStartable(rank)
        }
      } else {
        if (startable) {
          if (eliteStartable) {
            return eliteStarterIcon(rank)
          } else {
            return goodStarterIcon(rank)
          }
        } else {
          return badStarterIcon(rank)
        }
      }
    }
  }
}
