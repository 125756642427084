import React from "react"

export default class ForUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    let { back_url, teams } = this.props;

    return (
      <div>
        <ul style={{ listStyleType:"none" }}>
          <li>
            <a href={back_url}>Back</a>
          </li>

          {teams.map(team => {
            return (
              <li>
                <div className="table-container">
                  <table className="table table-bordered table-sm" style={{ width:"auto",margin:"auto" }}>
                    <thead>
                      <tr>
                        <th colSpan={7} className="text-center">{team.league_name}</th>
                      </tr>

                      <tr>
                        <th scope="col" style={{ width:"40px",height:"50px" }}>Position</th>
                        <th scope="col" style={{ width:"210px",height:"50px" }}>Name</th>
                        <th scope="col" style={{ width:"20px",height:"50px" }}>Age</th>
                        <th scope="col" style={{ width:"70px",height:"50px" }}>Dynasty</th>
                        <th scope="col" style={{ width:"70px",height:"50px" }}>Season</th>
                        <th scope="col" style={{ width:"70px",height:"50px" }}>Average</th>
                        <th scope="col" style={{ width:"40px",height:"50px" }}>Value</th>
                      </tr>
                    </thead>

                    <tbody>
                      {team.players.map(player => {
                        return (
                          <tr>
                            <td>{player.position}</td>
                            <td>
                              <a href={player.url}>{player.name}</a>
                            </td>
                            <td>{player.age}</td>
                            <td>{player.dynasty_rank}</td>
                            <td>{player.seasonal_rank}</td>
                            <td>{parseInt((player.seasonal_rank + player.dynasty_rank + player.dynasty_rank)/3)}</td>
                            <td>{player.overall_rank}</td>
                          </tr>
                        )
                      })}

                      <tr>
                        <td colSpan={7} className="text-center">
                          <a href={team.trade_url}>Trade Suggestions</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
