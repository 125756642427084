import React from "react"

export default class TeamRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { activePlayerIds, player, league } = this.props;
    let rosteredPlayer = league.rostered_players.find(rp => { return rp.player_id == player.id })

    if (rosteredPlayer && rosteredPlayer.team) {
      let team = rosteredPlayer.team
      let teamName = team.name
      let teamOwned = team.owned
      let teamColor = team.color
      let teamUrl = team.url
      let positionCount = team.players.filter(ply => { return ply.position == player.position }).length
      let isActive = activePlayerIds.find(id => id == rosteredPlayer.id)
      let percentage = isActive ? "99" : "25"
      let boxShadow = isActive ? "inset 0px 0px 0px 2px #000" : ""
      let color = isActive ? "black" : "#e4e4e4"

      if (teamOwned) {
        return (
          <li className="list-group-item" style={{ backgroundColor:`#FF0000${activePlayerIds.length > 0 ? percentage : ""}`,color:"white", boxShadow:`${boxShadow}`,height:"40px",maxHeight:"40px",padding:"6px 4px",width:"100px",fontSize:"10px" }} onClick={(e) => this.setActive(e, teamName, league.rostered_players)} value={teamName}>
            <div>
              <strong>{teamName}</strong>
            </div>

            <div style={{ fontSize:"8px" }}>
              {`${positionCount} On Roster`}
            </div>
          </li>
        )
      } else {
        return (
          <li className="list-group-item" style={{ backgroundColor:`${teamColor}${activePlayerIds.length > 0 ? percentage : "50"}`, boxShadow:`${boxShadow}`,height:"40px",maxHeight:"40px",padding:"6px 4px",width:"100px",fontSize:"10px" }} onClick={(e) => this.setActive(e, teamName, league.rostered_players)} value={teamName}>
            <div>
              <strong>{teamName}</strong>
            </div>

            <div style={{ fontSize:"8px" }}>
              {`${positionCount} On Roster`}
            </div>
          </li>
        )
      }
    } else {
      return (
        <li className="list-group-item" style={{ backgroundColor:`#00f7ff75`,height:"40px",maxHeight:"40px",padding:"12px 4px",width:"100px",fontSize:"10px" }}>
          FA
        </li>
      )
    }
  }
}
