import React from "react"

export default class Errors extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { errors } = this.props;

    if (errors) {
      return (
        <div className="text-center" style={{ color:"red",fontSize:"14px" }}>{errors}</div>
      )
    } else {
      return null;
    }
  }
}
