import React from "react";
import Errors from "../shared/Errors";
import { Modal } from "react-bootstrap";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errors: "",
      leagues: [],
      loading: false,
      password: "",
      passwordConfirmation: "",
      username: "",
      selectedLeagueId: "",
      showModal: false,
      url_set_active_league: ""
    }

    this.changeEmail = this.changeEmail.bind(this)
    this.changeLeague = this.changeLeague.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.changePasswordConfirmation = this.changePasswordConfirmation.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.onClick = this.onClick.bind(this)
    this.setPrimaryLeague = this.setPrimaryLeague.bind(this)
  }

  changeEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  changeLeague(e) {
    let leagueId = Number(e.target.value);

    this.setState({
      selectedLeagueId: leagueId
    })
  }

  changePassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  changePasswordConfirmation(e) {
    this.setState({
      passwordConfirmation: e.target.value
    })
  }

  changeUsername(e) {
    this.setState({
      username: e.target.value
    })
  }

  onClick() {
    this.setState({
      loading: true,
      showModal: true
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      username: this.state.username,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
      email: this.state.email,
      claimed: true,
    })
    formData.append('user', data)

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false,
          showModal: false
        })
      } else {
        if (data.leagues) {
          that.setState({
            leagues: data.leagues,
            errors: "",
            loading: false,
            selectedLeagueId: data.leagues[0].id,
            url_set_active_league: data.url_set_active_league
          })
        } else {
          window.location.assign(data.url)
        }
      }
    })
  }

  setPrimaryLeague() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      league_id: this.state.selectedLeagueId,
    })
    formData.append('user', data)

    fetch(this.state.url_set_active_league, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let {} = this.props;
    let {
      email,
      errors,
      leagues,
      loading,
      username,
      password,
      passwordConfirmation,
      selectedLeagueId,
      showModal,
    } = this.state;

    return (
      <div className="container" style={{ backgroundColor:"#162635",height:"100vh",padding:"40px 10px 10px",color:"white" }}>
        <div style={{ maxWidth:"300px",margin:"auto" }}>
          <a href="/">
            <img src="/assets/logo.svg" style={{ width:"100%" }}></img>
          </a>
        </div>

        <h2 style={{ textAlign:"center",fontSize:"40px",margin:"10px 0",fontWeight:"bold" }}>Sign Up</h2>

        <div className="form-group">
          <label>Sleeper Username</label>
          <input className="form-control" type="text" placeholder="Sleeper Username" value={username} onChange={this.changeUsername} disabled={loading ? "disabled" : null}></input>
          <small className="form-text text-muted">Once you sign up, this name cannot be changed.</small>
        </div>

        <div className="form-group">
          <label>Email</label>
          <input className="form-control" type="email" placeholder="Email" value={email} onChange={this.changeEmail} disabled={loading ? "disabled" : null}></input>
        </div>

        <div className="form-group">
          <label>Password</label>
          <input className="form-control" type="password" placeholder="Password" value={password} onChange={this.changePassword} disabled={loading ? "disabled" : null}></input>
        </div>

        <div className="form-group">
          <label>Password Confirmation</label>
          <input className="form-control" type="password" placeholder="Password Confirmation" value={passwordConfirmation} onChange={this.changePasswordConfirmation} disabled={loading ? "disabled" : null}></input>
        </div>

        <div className="form-group" style={{ marginTop:"15px" }}>
          <button className="btn btn-primary" onClick={this.onClick} disabled={loading ? "disabled" : null} style={{ width:"100%",backgroundColor:"#25bdff",fontWeight:"bold",fontSize:"20px" }}>Sign Up</button>
        </div>

        <div className="form-group" style={{ textAlign:"center" }}>
          <p style={{ padding:"15px 0" }}>
            <span style={{ paddingRight:"4px" }}>Already Registered?</span>
            <a href="/login" style={{ textDecoration:"none",color:"#25bdff" }}>Login now</a>
          </p>
        </div>

        <Errors errors={errors} />
        <LoadingModal
          changeLeague={this.changeLeague}
          errors={errors}
          leagues={leagues}
          loading={loading}
          onClick={this.setPrimaryLeague}
          selectedLeagueId={selectedLeagueId}
          showModal={showModal}
        />
      </div>
    )
  }
}

function LoadingModal(props) {
  let { changeLeague, errors, leagues, loading, onClick, selectedLeagueId, showModal } = props;

  let modalContent = () => {
    if (leagues.length > 0) {
      return (
        <>
          <div className="form-group">
            <select className="form-select" value={selectedLeagueId} onChange={changeLeague}>
              {leagues.map(league => {
                return (
                  <option key={league.id} value={league.id}>{league.name}</option>
                )
              })}
            </select>
          </div>

          <div className="form-group" style={{ marginTop:"15px" }}>
            <button className="btn btn-primary" onClick={onClick} disabled={loading ? "disabled" : null} style={{ width:"100%",backgroundColor:"#25bdff",fontWeight:"bold",fontSize:"20px" }}>
              Set Free League
            </button>
          </div>

          <Errors errors={errors} />
        </>
      )
    } else {
      return (
        <>
          <h2 className="text-center">Loading your leagues</h2>
          <p className="text-center">This may take a minute</p>

          <p className="text-center" style={{ fontSize:"100px" }}>
            <i className="fas fa-spinner fa-spin"></i>
          </p>
        </>
      )
    }
  }

  return (
    <Modal show={showModal} backdrop="static" keyboard={false} centered>
      <Modal.Body>
        {modalContent()}
      </Modal.Body>
    </Modal>
  )
}
