import React from "react"

export default class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { lastUpdated, page, urls } = this.props;

    return (
      <div className="row navbar-row">
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark-blue">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <a className="brand" style={{ margin:"0",float:"none",top:"0" }} href="/">
              <img src="/assets/logo.svg" alt="logo" title="logo"></img>
            </a>

            <div className="collapse navbar-collapse" id="navbarNavAltMarkup" style={{ justifyContent:"space-between" }}>
              <ul className="navbar-nav mr-auto">
                <li className={`nav-item ${page == "leagues" ? "active" : ""}`}><a href={urls.leagues_path} className="nav-link">Leagues</a></li>
                <li className={`nav-item ${page == "teams" ? "active" : ""}`}><a href={urls.teams_path} className="nav-link">Teams</a></li>
              </ul>

              <ul className="navbar-nav navbar-nav-right">
                <li className={`nav-item ${page == "players" ? "active" : ""}`}><a href={urls.players_path} className="nav-link">Players</a></li>
                <li className={`nav-item ${page == "profile" ? "active" : ""}`}><a href={urls.profile_path} className="nav-link">Profile</a></li>
              </ul>
            </div>
          </div>

          <div style={{ textAlign:"center",padding:"6px 4px 0",fontSize:"10px",color:"#FFFFFF70",margin:"auto" }}>
            <i className="fas fa-exclamation-triangle" style={{ paddingRight:"4px" }}></i>

            Player values last updated {lastUpdated} ago
          </div>
        </nav>
      </div>
    )
  }
}
