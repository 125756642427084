import React from "react"

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    let { users } = this.props;

    return (
      <div>
        <ul>
          {users.map(user => {
            return (
              <li>
                <a href={user.url}>{`${user.username} (${user.team_count})`}</a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
