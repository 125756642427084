import React from "react"
import Header from "../shared/Header";

export default class NoLeagues extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    let { last_updated, urls } = this.props;

    return (
      <div className="container-fluid">
        <Header
          page={"teams"}
          urls={urls}
          lastUpdated={last_updated}
        />

        <div style={{ backgroundColor:"#5a5a5a25",marginBottom:"10px",padding:"10px",borderRadius:"4px" }}>
          <div className="row">
            <div className="col">
              <div className="text-center">
                <h1 style={{ marginBottom:"0",fontSize:"30px",paddingBottom:"10px" }}>
                  <strong>No Leagues Found</strong>
                </h1>
                <h4 style={{ fontSize:"14px" }}>
                  In order to use the <strong>Dynasty Cheat Sheet</strong>, you will need to join a dynasty league on the sleeper platform!
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
