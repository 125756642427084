import React from "react"

export default class TrendingValueIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { dynastyRank, seasonalRank, size } = this.props;

    if ((dynastyRank - seasonalRank) < -10) {
      return (
        <i className={`fas fa-angle-double-up ${size == "small" ? "fs-10px" : "fs-12px"}`} style={{ color:"green",position:"absolute",left:"0",right:"0",top:"16px" }}></i>
      )
    } else if ((dynastyRank - seasonalRank) < -5) {
      return (
        <i className={`fas fa-angle-up ${size == "small" ? "fs-10px" : "fs-12px"}`} style={{ color:"green",position:"absolute",left:"0",right:"0",top:"16px" }}></i>
      )
    } else if ((dynastyRank - seasonalRank) > 10) {
      return (
        <i className={`fas fa-angle-double-down ${size == "small" ? "fs-10px" : "fs-12px"}`} style={{ color:"red",position:"absolute",left:"0",right:"0",top:"16px" }}></i>
      )
    } else if ((dynastyRank - seasonalRank) > 5) {
      return (
        <i className={`fas fa-angle-down ${size == "small" ? "fs-10px" : "fs-12px"}`} style={{ color:"red",position:"absolute",left:"0",right:"0",top:"16px" }}></i>
      )
    } else {
      return (
        <i className="fas fa-circle fs-4px" style={{ color:"#5a5a5a30",position:"absolute",left:"0",right:"0",top:"20px" }}></i>
      )
    }
  }
}
