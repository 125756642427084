import React from "react"

export default class SleeperStatusIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { taxi } = this.props;

    if (taxi) {
      return (
        <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"orange",padding:"1px 2px",borderRadius:"4px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
          <strong>TX</strong>
        </span>
      )
    }
  }
}
