import React from "react"
import TrendingValueIcon from "../shared/icons/TrendingValueIcon";
import EarlyServiceIcon from "../shared/icons/EarlyServiceIcon";
import SleeperStatusIcon from "../shared/icons/SleeperStatusIcon";
import PlayerPosition from "../shared/icons/PlayerPosition";

export default class PlayerRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { player, rank } = this.props;

    let nameShortener = (name) => {
      if (name.length > 18) {
        return (
          <span>{`${name.split(" ")[0][0]}. ${name.split(" ")[1]}`}</span>
        )
      } else {
        return (
          <span>{name}</span>
        )
      }
    }

    return (
      <li className="list-group-item" style={{ padding:"4px 4px 4px 0",backgroundColor:"white",height:"40px",maxHeight:"40px" }}>
        <div className="d-flex" style={{ paddingBottom:"3px" }}>
          <div style={{ fontSize:"20px",width:"24px" }}>
            <strong>
              <div className="text-center fs-10px" style={{ padding:"2px 0",height:"16px",margin:"5px 0" }}>{rank}</div>
            </strong>
          </div>

          <div>
            <div className="d-flex">
              <span style={{ fontSize:"12px",maxWidth:"108px",textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap",display:"block" }}>
                <a className={`${player.reserve ? "text-decoration-line-through" : "text-decoration-none"}`} style={{ paddingRight:"4px",fontWeight:"normal",color:`${player.reserve ? "red" : "black"}` }}>
                  {nameShortener(player.name)}
                </a>
              </span>
              <span style={{ position:"relative",top:"-1px",marginLeft:"-3px" }}>
                {<EarlyServiceIcon rookie={player.rookie} sophomore={player.sophomore} junior={player.junior} />}
              </span>
            </div>

            <div style={{ fontSize:"8px" }}>
              {<PlayerPosition superflex={false} position={player.position} />}
              <span> | </span>
              <span>{player.age} yrs</span>
              <span> | </span>
              <span style={{ fontStyle:"italic" }}>{player.overall_rank}</span>
            </div>
          </div>

          <div className="d-flex text-center" style={{ right:"0",position:"absolute",fontSize:"14px",padding:"0 3px 0 0" }}>
            <div style={{ width:"20px",position:"relative",top:"-2px" }}>
              <div style={{ fontSize:"10px" }}>{player.dynasty_rank}</div>

              <div className="p-0" style={{ color:"#5a5a5a50",position:"relative",height:"6px",top:"-18px" }}>
                {<TrendingValueIcon dynastyRank={player.dynasty_rank} seasonalRank={player.seasonal_rank} size={"small"} />}
              </div>

              <div style={{ fontSize:"10px" }}>{player.seasonal_rank}</div>
            </div>
          </div>
        </div>
      </li>
    )
  }
}
