import React from "react"
import PlayerRow from "./PlayerRow";
import PickRow from "./PickRow";

export default class PlayersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      qbSelected: this.props.league.superflex || this.props.league.two_qb,
      rbSelected: false,
      wrSelected: false,
      teSelected: this.props.league.te_premium,
    }

    this.selectQb = this.selectQb.bind(this)
    this.selectRb = this.selectRb.bind(this)
    this.selectWr = this.selectWr.bind(this)
    this.selectTe = this.selectTe.bind(this)
  }

  selectQb() {
    let qbSelected = !this.state.qbSelected

    this.setState({
      qbSelected: qbSelected
    })
  }

  selectRb() {
    let rbSelected = !this.state.rbSelected

    this.setState({
      rbSelected: rbSelected
    })
  }

  selectWr() {
    let wrSelected = !this.state.wrSelected

    this.setState({
      wrSelected: wrSelected
    })
  }

  selectTe() {
    let teSelected = !this.state.teSelected

    this.setState({
      teSelected: teSelected
    })
  }

  render () {
    let { checkable, draft_rostered_player_url, picks, players, league, team, rowNumber } = this.props;
    let { qbSelected, rbSelected, wrSelected, teSelected } = this.state;

    let positionHighlight = (position, count) => {
      let onClick = null;

      if (position == "QB") {
        onClick = this.selectQb
      } else if (position == "RB") {
        onClick = this.selectRb
      } else if (position == "WR") {
        onClick = this.selectWr
      } else if (position == "TE") {
        onClick = this.selectTe
      }

      if (position == "QB" && qbSelected) {
        return (
          <li className="hoverable" style={{ flex:"1",padding:"5px",margin:"5px",color:"#003cff",fontSize:"16px",fontWeight:"bold",borderRadius:"4px",backgroundColor:"#003cff25",border:"1px solid #003cff" }} onClick={onClick}>
            {position}
            <span style={{ fontSize:"10px",padding:"0 2px" }}>[{count}]</span>
          </li>
        )
      } else if (position == "RB" && rbSelected) {
        return (
          <li className="hoverable" style={{ flex:"1",padding:"5px",margin:"5px",color:"#1cb302",fontSize:"16px",fontWeight:"bold",borderRadius:"4px",backgroundColor:"#1cb30225",border:"1px solid #1cb302" }} onClick={onClick}>
            {position}
            <span style={{ fontSize:"10px",padding:"0 2px" }}>[{count}]</span>
          </li>
        )
      } else if (position == "WR" && wrSelected) {
        return (
          <li className="hoverable" style={{ flex:"1",padding:"5px",margin:"5px",color:"#ff9900",fontSize:"16px",fontWeight:"bold",borderRadius:"4px",backgroundColor:"#ff990025",border:"1px solid #ff9900" }} onClick={onClick}>
            {position}
            <span style={{ fontSize:"10px",padding:"0 2px" }}>[{count}]</span>
          </li>
        )
      } else if (position == "TE" && teSelected) {
        return (
          <li className="hoverable" style={{ flex:"1",padding:"5px",margin:"5px",color:"#e340bd",fontSize:"16px",fontWeight:"bold",borderRadius:"4px",backgroundColor:"#e340bd25",border:"1px solid #e340bd" }} onClick={onClick}>
            {position}
            <span style={{ fontSize:"10px",padding:"0 2px" }}>[{count}]</span>
          </li>
        )
      } else {
        return (
          <li className="hoverable" style={{ flex:"1",padding:"5px",margin:"5px",color:"#16263575",fontSize:"16px",borderRadius:"4px",backgroundColor:"white",border:"1px solid #16263575" }} onClick={onClick}>
            {position}
            <span style={{ fontSize:"10px",padding:"0 2px" }}>[{count}]</span>
          </li>
        )
      }
    }
    let isPositionSelected = (position) => {
      if (position == "QB") {
        return this.state.qbSelected
      } else if (position == "RB") {
        return this.state.rbSelected
      } else if (position == "WR") {
        return this.state.wrSelected
      } else if (position == "TE") {
        return this.state.teSelected
      }
    }
    let assets = players.concat(picks);
    // let assets = players.concat(picks).sort((a, b) => b.value - a.value);

    return (
      <div id={`collapse-${rowNumber}`} className="accordion-collapse collapse" aria-labelledby={`heading-${0}`}>
        <div className="accordion-body" style={{ padding:"0",margin:"0" }}>
          <ul style={{ display:"flex",listStyleType:"none",padding:"0",margin:"0",textAlign:"center" }}>
            {positionHighlight("QB", assets.filter(asset => { return asset.category == "player" && asset.position == "QB" }).length)}
            {positionHighlight("RB", assets.filter(asset => { return asset.category == "player" && asset.position == "RB" }).length)}
            {positionHighlight("WR", assets.filter(asset => { return asset.category == "player" && asset.position == "WR" }).length)}
            {positionHighlight("TE", assets.filter(asset => { return asset.category == "player" && asset.position == "TE" }).length)}
          </ul>

          <ul className="list-group">
            {assets.map((asset,i) => {
              if (asset.category == "pick") {
                return (
                  <PickRow
                    rank={i+1}
                    pick={asset}
                  />
                )
              } else {
                let playerIsNotCore = null
                let playerIsNotStarter = null
                let teamRank = i+1
                let totalStarters = null

                if (team) {
                  playerIsNotCore = team.core_player_ids.indexOf(asset.id) < 0
                  playerIsNotStarter = team.weekly_starter_ids.indexOf(asset.id) < 0
                  totalStarters = team.total_starters
                } else {
                  playerIsNotCore = true
                  playerIsNotStarter = true
                  totalStarters = asset.total_starters
                }

                {
                  return (
                    <PlayerRow
                      league={league}
                      player={asset}
                      playerIsNotCore={playerIsNotCore}
                      playerIsNotStarter={playerIsNotStarter}
                      rank={teamRank}
                      totalStarters={totalStarters}
                      selected={isPositionSelected(asset.position)}
                      checkable={checkable}
                      draft_rostered_player_url={draft_rostered_player_url}
                      team={team}
                    />
                  )
                }
              }
            })}
          </ul>
        </div>
      </div>
    )
  }
}
