import React from "react"
import PlayersList from "../teams/PlayersList";
import Header from "../shared/Header";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedPlayers: this.props.players
    }
  }

  render () {
    let { sortedPlayers } = this.state;
    let { last_updated, league, urls } = this.props;

    return (
      <div className="container-fluid">
        <Header
          page={"players"}
          urls={urls}
          lastUpdated={last_updated}
        />

        <div className="row">
          <div className="accordion">
            <div className="accordion-item" style={{ marginBottom:"5px",border:"2px solid #5a5a5a25",borderRadius:"4px" }}>
              <h2 className="accordion-header" id={`heading-${0}`}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${0}`} aria-expanded="false" aria-controls={`collapse-${0}`} style={{ padding:"6px",height:"55px" }}>
                  <div className="text-center" style={{ width:"100%" }}>
                    <div style={{ fontSize:"16px",padding:"4px" }}>
                      <strong>
                        Players
                      </strong>
                    </div>
                  </div>
                </button>
              </h2>

              <PlayersList
                checkable={false}
                league={league}
                players={sortedPlayers}
                rowNumber={0}
                team={null}
                draft_rostered_player_url={null}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
