import React from "react"

export default class EarlyServiceIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { rookie, sophomore, junior } = this.props;

    if (rookie) {
      return (
        <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"#800080",padding:"1px 2px",borderRadius:"100px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
          <strong>R</strong>
        </span>
      )
    } else if (sophomore) {
      return (
        <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"#80008070",padding:"1px 2px",borderRadius:"100px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
          <strong>2</strong>
        </span>
      )
    } else if (junior) {
      return (
        <span className="text-center color-white d-inline-block" style={{ fontSize:"7px",backgroundColor:"#80008040",padding:"1px 2px",borderRadius:"100px",minWidth:"12px",height:"12px",marginLeft:"2px" }}>
          <strong>3</strong>
        </span>
      )
    }
  }
}
