import React from "react"
import EarlyServiceIcon from "../shared/icons/EarlyServiceIcon";
import TrendingValueIcon from "../shared/icons/TrendingValueIcon";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false
    }

    this.setChecked = this.setChecked.bind(this)
  }

  setChecked() {
    let currentChecked = this.state.checked;

    this.setState({
      checked: !currentChecked
    })
  }

  render () {
    let { player, rank } = this.props;
    let { checked } = this.state;

    return (
      <tr style={{ backgroundColor:`${checked ? "red" : "white"}` }}>
        <td>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={checked ? "checked" : null} onChange={this.setChecked}></input>
          </div>
        </td>
        <td>{rank}</td>
        <td>{player.position}</td>
        <td>
          {<EarlyServiceIcon rookie={player.rookie} sophomore={player.sophomore} junior={player.junior} />}
          <a className="color-black" href={player.url}>{player.name}</a>
          {<TrendingValueIcon dynastyRank={player.dynasty_rank} seasonalRank={player.dynasty_rank} size={"large"} />}
        </td>
        <td>{player.age}</td>
        <td>{player.dynasty_rank}</td>
        <td>{player.seasonal_rank}</td>
        <td>{`${player.position}${parseInt((player.seasonal_rank + player.dynasty_rank + player.dynasty_rank)/3)}`}</td>
        <td>{player.overall_rank}</td>
        <td>{player.week_half}</td>
        <td>{player.week_ppr}</td>
      </tr>
    )
  }
}
