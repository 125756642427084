import React from "react"

export default class PlayerPosition extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { position, selected } = this.props;

    if (selected) {
      if (position == "QB") {
        // blue
        return (
          <span style={{ color:"#003cff",fontWeight:"bold",backgroundColor:"#003cff25",padding:"0 1px",borderRadius:"2px",fontSize:"8px",width:"20px",display:"inline-block",textAlign:"center",border:"1px solid #003cff" }}>{position}</span>
        )
      } else if (position == "RB") {
        // magenta
        return (
          <span style={{ color:"#1cb302",fontWeight:"bold",backgroundColor:"#1cb30225",padding:"0 1px",borderRadius:"2px",fontSize:"8px",width:"20px",display:"inline-block",textAlign:"center",border:"1px solid #1cb302" }}>{position}</span>
        )
      } else if (position == "WR") {
        // green
        return (
          <span style={{ color:"#ff9900",fontWeight:"bold",backgroundColor:"#ff990025",padding:"0 1px",borderRadius:"2px",fontSize:"8px",width:"20px",display:"inline-block",textAlign:"center",border:"1px solid #ff9900" }}>{position}</span>
        )
      } else if (position == "TE") {
        // yellow
        return (
          <span style={{ color:"#e340bd",fontWeight:"bold",backgroundColor:"#e340bd25",padding:"0 1px",borderRadius:"2px",fontSize:"8px",width:"20px",display:"inline-block",textAlign:"center",border:"1px solid #e340bd" }}>{position}</span>
        )
      }
    } else {
      return (
        <span style={{ color:"black",padding:"0",borderRadius:"2px",fontSize:"10px",width:"20px",display:"inline-block",textAlign:"center" }}>{position}</span>
      )
    }
  }
}
