import React from "react"

export default class ForLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    let { back_url, trades } = this.props;

    return (
      <div>
        <ul>
          <li>
            <a href={back_url}>Back</a>
          </li>

          {trades.map(trade => {
            return (
              <li>
                {trade.occurred_at}
                <div>{trade.league.name}</div>

                <table className="table table-bordered table-sm">
                  <thead className="header">
                    <tr>
                      {trade.team_names.map(team => {
                        return (
                          <th scope="col">
                            {/* <a href={team.url}>{team.name} ({team.trade_values})</a> */}
                            <a href={team.url}>{team.name}</a>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      {trade.team_names.map(team => {
                        return (
                          <td>
                            <ul style={{ listStyleType:"none" }}>
                              {trade.details.filter(detail => detail.team.name == team.name).map(detail => {
                                {
                                  if (detail.transaction_type == "add") {
                                    return (
                                      <li style={{ color:"green" }}>
                                        <i style={{ paddingRight:"4px" }} className="fas fa-plus"></i>

                                        <a href={detail.player.url}>{detail.player.name} ({detail.player.value})</a>
                                      </li>
                                    )
                                  } else {
                                    return (
                                      <li style={{ color:"red" }}>
                                        <i style={{ paddingRight:"4px" }} className="fas fa-minus"></i>

                                        <a href={detail.player.url}>{detail.player.name} ({detail.player.value})</a>
                                      </li>
                                    )
                                  }
                                }
                              })}
                            </ul>
                          </td>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
