import React from "react";
import Errors from "../shared/Errors";
import { Modal } from "react-bootstrap";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: "",
      loading: false,
      password: "",
      username: "",
      showModal: false
    }

    this.changePassword = this.changePassword.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  changePassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  changeUsername(e) {
    this.setState({
      username: e.target.value
    })
  }

  onClick() {
    this.setState({
      loading: true,
      showModal: true
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      username: this.state.username,
      password: this.state.password,
    })
    formData.append('user', data)

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false,
          showModal: false
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let {} = this.props;
    let {
      errors,
      loading,
      username,
      password,
      showModal,
    } = this.state;

    return (
      <div className="container" style={{ backgroundColor:"#162635",height:"100vh",padding:"40px 10px 10px",color:"white" }}>
        <div style={{ maxWidth:"300px",margin:"auto" }}>
          <a href="/">
            <img src="/assets/logo.svg" style={{ width:"100%" }}></img>
          </a>
        </div>

        <h2 style={{ textAlign:"center",fontSize:"40px",margin:"10px 0",fontWeight:"bold" }}>Login</h2>

        <div className="form-group">
          <label>Sleeper Username</label>
          <input className="form-control" type="text" placeholder="Sleeper Username" value={username} onChange={this.changeUsername} disabled={loading ? "disabled" : null}></input>
        </div>

        <div className="form-group">
          <label>Password</label>
          <input className="form-control" type="password" placeholder="Password" value={password} onChange={this.changePassword} disabled={loading ? "disabled" : null}></input>
        </div>

        <div className="form-group" style={{ marginTop:"15px" }}>
          <button className="btn btn-primary" onClick={this.onClick} disabled={loading ? "disabled" : null} style={{ width:"100%",backgroundColor:"#25bdff",fontWeight:"bold",fontSize:"20px" }}>
            Login
          </button>
        </div>

        <div className="form-group" style={{ textAlign:"center" }}>
          <p style={{ padding:"15px 0" }}>
            <span style={{ paddingRight:"4px" }}>Not Registered?</span>
            <a href="/signup" style={{ textDecoration:"none",color:"#25bdff" }}>Create an account</a>
          </p>
        </div>

        <Errors errors={errors} />
        <LoadingModal showModal={showModal} />
      </div>
    )
  }
}

function LoadingModal(props) {
  let { showModal } = props;

  let modalContent = () => {
    return (
      <>
        <h2 className="text-center">Updating your leagues</h2>
        <p className="text-center">This may take a minute</p>

        <p className="text-center" style={{ fontSize:"100px" }}>
          <i className="fas fa-spinner fa-spin"></i>
        </p>
      </>
    )
  }

  return (
    <Modal show={showModal} backdrop="static" keyboard={false} centered>
      <Modal.Body>
        {modalContent()}
      </Modal.Body>
    </Modal>
  )
}
