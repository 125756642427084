import React from "react"
import Header from "../shared/Header";
import PlayerRow from "./PlayerRow";
import TeamRow from "./TeamRow";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePlayerIds: [],
      loading: false,
      loadedPlayers: this.props.players,
      selectedPosition: this.props.selected_position,
      currentlyLoading: "",
    }

    this.getPlayers = this.getPlayers.bind(this)
    this.setActive = this.setActive.bind(this)
    this.updateLeagues = this.updateLeagues.bind(this)
  }

  getPlayers(e) {
    let that = this;
    this.setState({
      loading: true,
      currentlyLoading: e.target.value,
      selectedPosition: e.target.value,
    })

    let data = JSON.stringify({
      position: e.target.value,
    })

    fetch(this.props.fetch_url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loading: false,
        })
      } else {
        that.setState({
          selectedPosition: e.target.value,
          loadedPlayers: data.players,
          loading: false,
          currentlyLoading: "",
          activePlayerIds: []
        })
      }
    })
  }

  setActive(e, teamName, players) {
    e.stopPropagation();
    let playerIds = players.filter(ply => ply.team.name == teamName).map(ply => ply.id)
    let activePlayerIds = arraysEqual(playerIds, this.state.activePlayerIds) ? [] : playerIds

    this.setState({
      activePlayerIds: activePlayerIds
    })
  }

  updateLeagues() {
    let that = this;
    this.setState({
      loading: true,
    })

    fetch(this.props.update_leagues_url, {
      method: 'PUT',
      headers: {},
      body: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loading: false,
        })
      } else {
        window.location.reload();
      }
    })
  }

  render () {
    let {
      last_updated,
      leagues,
      urls,
    } = this.props;
    let {
      activePlayerIds,
      currentlyLoading,
      loading,
      loadedPlayers,
      selectedPosition,
    } = this.state;

    let updateLeaguesButton = () => {
      if (loading) {
        return (
          <button className="color-white" style={{ border:"none",backgroundColor:"lightgreen",fontSize:"20px",padding:"10px 20px",borderRadius:"4px" }} disabled="disabled">
            <strong>
              <i className="fas fa-spinner fa-spin"></i>
              <span style={{ paddingLeft:"5px" }}>Updating...</span>
            </strong>
          </button>
        )
      } else {
        return (
          <button className="color-white" style={{ border:"none",backgroundColor:"green",fontSize:"20px",padding:"10px 20px",borderRadius:"4px" }} onClick={this.updateLeagues}>
            <strong>Refresh All Leagues</strong>
          </button>
        )
      }
    }

    return (
      <div className="container-fluid">
        <Header
          page={"leagues"}
          urls={urls}
          lastUpdated={last_updated}
        />

        <div className="row" style={{ marginTop:"65px" }}>
          <div className="col">
            <div className="text-center">
              {updateLeaguesButton()}
            </div>
          </div>
        </div>

        <div className="row" style={{ margin:"10px 0" }}>
          <div className="col">
            <ul className="list-group" style={{ display:"flex",flexFlow:"row wrap",justifyContent:"space-around" }}>
              <li className="list-group-item" style={{ flex:"3 0px",border:"none",padding:"0",margin:"0 2px" }}>
                <button onClick={this.getPlayers} value="QB" className={`btn ${selectedPosition == 'QB' ? 'btn-primary' : 'btn-secondary'}`} style={{ width:'100%',flex:"1" }} disabled={loading ? "disabled" : null}>
                  {
                    loading && currentlyLoading == "QB" ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      "QB"
                    )
                  }
                </button>
              </li>
              <li className="list-group-item" style={{ flex:"3 0px",border:"none",padding:"0",margin:"0 2px" }}>
                <button onClick={this.getPlayers} value="RB" className={`btn ${selectedPosition == 'RB' ? 'btn-primary' : 'btn-secondary'}`} style={{ width:'100%',flex:"1" }} disabled={loading ? "disabled" : null}>
                  {
                    loading && currentlyLoading == "RB" ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      "RB"
                    )
                  }
                </button>
              </li>
              <li className="list-group-item" style={{ flex:"3 0px",border:"none",padding:"0",margin:"0 2px" }}>
                <button onClick={this.getPlayers} value="WR" className={`btn ${selectedPosition == 'WR' ? 'btn-primary' : 'btn-secondary'}`} style={{ width:'100%',flex:"1" }} disabled={loading ? "disabled" : null}>
                  {
                    loading && currentlyLoading == "WR" ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      "WR"
                    )
                  }
                </button>
              </li>
              <li className="list-group-item" style={{ flex:"3 0px",border:"none",padding:"0",margin:"0 2px" }}>
                <button onClick={this.getPlayers} value="TE" className={`btn ${selectedPosition == 'TE' ? 'btn-primary' : 'btn-secondary'}`} style={{ width:'100%',flex:"1" }} disabled={loading ? "disabled" : null}>
                  {
                    loading && currentlyLoading == "TE" ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      "TE"
                    )
                  }
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="row flex-row">
          <div className="col" style={{ flex:"0 0 auto",width:"180px",paddingRight:"0" }}>
            <ul className="list-group">
              <li className="list-group-item d-flex" style={{ padding:"4px 4px 4px 0",backgroundColor:"white",height:"70px",maxHeight:"70px" }}></li>

              {loadedPlayers.map((player,i) => {
                {
                  return (
                    <PlayerRow
                      player={player}
                      rank={i+1}
                    />
                  )
                }
              })}
            </ul>
          </div>

          <div className="col" style={{ flex:"0 0 auto",width:"calc(100% - 180px)",paddingLeft:"0" }}>
            <div style={{ display:"flex" }} className="scrollable">
              {leagues.map(league => {
                return (
                  <ul className="list-group">
                    <li className="list-group-item" style={{ backgroundColor:"#5a5a5a25",color:"black",height:"70px",maxHeight:"70px",padding:"2px",width:"100px",fontSize:"10px",textAlign:"center" }}>
                      <div style={{ position:"relative",top:"50%",transform:"translateY(-50%)" }}>
                        <div>
                          <img style={{ width:"20px",maxHeight:"20px",margin:"0 6px",position:"relative" }} src={league.logo}></img>
                        </div>

                        <div>
                          <strong>{league.name}</strong>
                        </div>
                      </div>
                    </li>

                    {loadedPlayers.map(player => {
                      return (
                        <TeamRow
                          player={player}
                          league={league}
                          activePlayerIds={activePlayerIds}
                        />
                      )
                    })}
                  </ul>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
