import React from "react"

export default class PickRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { pick, rank } = this.props;

    return (
      <li className="list-group-item d-flex" style={{ padding:"4px 4px 4px 0",height:"47px",backgroundColor:"white" }}>
        <div style={{ fontSize:"20px",width:"24px",height:"37px",lineHeight:"37px" }}>
          <strong>
            <div className="text-center fs-12px" style={{ width:"16px",margin:"0 3px" }}>{rank}</div>
          </strong>
        </div>

        <div>
          {
            pick.original_team ?
              <>
                <span style={{ fontSize:"14px" }}>
                  <a className="text-decoration-none" style={{ paddingRight:"4px",fontWeight:"normal",color:"black",fontWeight:`${pick.round == 1 ? "bold" : "normal"}` }}>
                    {pick.name}
                  </a>
                </span>

                <div style={{ fontSize:"10px",marginLeft:"-2px" }}>
                  {pick.original_team}
                </div>
              </>
            :
              <span style={{ fontSize:"14px",height:"37px",lineHeight:"37px" }}>
                <a className="text-decoration-none" style={{ paddingRight:"4px",fontWeight:"normal",color:"black",fontWeight:`${pick.round == 1 ? "bold" : "normal"}` }}>
                  {pick.name}
                </a>
              </span>
          }
        </div>

        <div className="d-flex text-center" style={{ right:"0",position:"absolute",fontSize:"14px",padding:"2px 0" }}>
          <div style={{ width:"40px" }}>
            <div style={{ fontSize:"7px",color:"#5a5a5a" }}>VALUE</div>
            <div className="fs-12px" style={{ padding:"2px 1px",margin:"0px 4px" }}>{pick.value}</div>
          </div>
        </div>
      </li>
    )
  }
}
