import React from "react"
import Header from "../shared/Header";
import PlayersList from "./PlayersList";
import Legend from "./Legend";
import Select from "react-select";
import { Modal } from "react-bootstrap";

export default class ForLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingNew: false,
      showModal: false,
    }

    this.onChange = this.onChange.bind(this)
    this.updateLeague = this.updateLeague.bind(this)
    this.addNewLeagues = this.addNewLeagues.bind(this)
  }

  onChange(e) {
    this.setState({
      showModal: true
    })

    window.location.assign(e.value)
  }

  addNewLeagues() {
    let that = this;
    this.setState({
      loadingNew: true,
    })

    fetch(this.props.add_new_leagues_url, {
      method: 'PUT',
      headers: {},
      body: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loadingNew: false,
        })
      } else {
        window.location.reload();
      }
    })
  }

  updateLeague(league) {
    let that = this;

    if (league.pending) {
      if (confirm("Has your league finished drafting?")) {
        this.setState({
          loading: true,
        })

        fetch(this.props.update_league_url, {
          method: 'PUT',
          headers: {},
          body: {}
        }).then(response => {
          return response.json();
        }).then((data) => {
          if (data.errors) {
            that.setState({
              loading: false,
            })
          } else {
            window.location.reload();
          }
        })
      }
    } else {
      this.setState({
        loading: true,
      })

      fetch(this.props.update_league_url, {
        method: 'PUT',
        headers: {},
        body: {}
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            loading: false,
          })
        } else {
          window.location.reload();
        }
      })
    }
  }

  goToLegend = () => {
    const legend = document.getElementById("legend");

    window.scrollTo({
      top: legend.offsetTop - 87,
      behavior: "smooth"
    })
  };

  render () {
    let {
      best_available,
      best_core_score,
      current_week,
      last_updated,
      leagues,
      league,
      teams,
      subtitle,
      urls,
      username,
    } = this.props;
    let { loading, loadingNew, showModal } = this.state;

    let teamRank = (team, rowNumber) => {
      if (username === team.team_name) {
        return (
          <div className="text-center color-white" style={{ backgroundColor:"#25bdff",padding:"3px",borderRadius:"4px",marginRight:"3px",width:"30px",height:"30px",fontSize:"20px" }}>
            <strong>{rowNumber}</strong>
          </div>
        )
      } else {
        return (
          <div className="text-center" style={{ padding:"3px",marginRight:"3px",width:"30px",height:"30px",fontSize:"20px" }}>
            <strong>{rowNumber}</strong>
          </div>
        )
      }

    }
    let checkIfClaimed = (claimed) => {
      if (claimed) {
        return (
          <i className="fas fa-check-circle" style={{ color:"#25bdff",fontSize:"12px",paddingRight:"2px",paddingTop:"4px" }}></i>
        )
      } else {
        return null;
      }
    }
    let checkIfOrphaned = (team_name, orphaned, leaguePending) => {
      if (orphaned) {
        return (
          <span style={{ color:"#5a5a5a70",fontStyle:"italic" }}>{team_name}</span>
        )
      } else {
        if (leaguePending) {
          return (
            <strong>{team_name}</strong>
          )
        } else {
          return (
            <strong style={{ width:"165px",display:"inline-block",textOverflow:"ellipsis",overflow:"auto" }}>{team_name}</strong>
          )
        }
      }
    }
    let teamName = (team, leaguePending) => {
      let teamSize = team.roster_size;

      if (leaguePending || teamSize == 0) {
        return (
          <div style={{ fontSize:"16px",padding:"4px" }}>
            <div style={{ display:"flex" }}>
              {checkIfClaimed(team.user.claimed)}
              {checkIfOrphaned(team.team_name, team.orphaned, true)}
            </div>
          </div>
        )
      } else if (teamSize > 0) {
        return (
          <div style={{ fontSize:"16px",padding:"4px",paddingTop:"0" }}>
            <div style={{ display:"flex" }}>
              {checkIfClaimed(team.user.claimed)}
              {checkIfOrphaned(team.team_name, team.orphaned, false)}
            </div>

            <div style={{ fontSize:"10px" }}>
              <span>Roster Spots: </span>
              <span style={{ color:"green",fontWeight:"bold" }}>{teamSize} Available</span>
            </div>
          </div>
        )
      } else if (teamSize < 0) {
        return (
          <div style={{ fontSize:"16px",padding:"4px",paddingTop:"0" }}>
            <div style={{ display:"flex" }}>
              {checkIfClaimed(team.user.claimed)}
              {checkIfOrphaned(team.team_name, team.orphaned, false)}
            </div>

            <div style={{ fontSize:"10px" }}>
              <span>Roster Spots: </span>
              <span style={{ color:"red",fontWeight:"bold" }}>{Math.abs(teamSize)} Over</span>
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ fontSize:"16px",padding:"4px" }}>
            <div style={{ display:"flex" }}>
              {checkIfClaimed(team.user.claimed)}
              {checkIfOrphaned(team.team_name, team.orphaned)}
            </div>
          </div>
        )
      }
    }
    let updateLeagueButton = (league) => {
      if (loading) {
        return (
          <button className="color-white" style={{ border:"none",backgroundColor:"lightgreen",fontSize:"16px",padding:"5px 10px",borderRadius:"4px" }} disabled="disabled">
            <strong>
              <i className="fas fa-spinner fa-spin"></i>
              <span style={{ paddingLeft:"5px" }}>Updating...</span>
            </strong>
          </button>
        )
      } else {
        if (league.recently_updated) {
          return (
            <button className="color-white" style={{ border:"none",backgroundColor:"gray",fontSize:"16px",padding:"5px 10px",borderRadius:"4px" }} disabled="disabled">
              <strong>Recenty Updated</strong>
              <div style={{ fontSize:"8px",fontStyle:"italic" }}>Unlocks in ~{league.time_left_until_unlock} minute{league.time_left_until_unlock > 1 ? "s" : ""}</div>
            </button>
          )
        } else {
          if (league.pending) {
            return (
              <button className="color-white" style={{ border:"none",backgroundColor:"orange",fontSize:"16px",padding:"5px 10px",borderRadius:"4px" }} onClick={() => this.updateLeague(league)} disabled={loadingNew ? "disabled" : ""}>
                <strong>Draft Over?</strong>
                <div style={{ fontSize:"8px",fontStyle:"italic" }}>Wait until your league's draft is complete before clicking.</div>
              </button>
            )
          } else {
            return (
              <button className="color-white" style={{ border:"none",backgroundColor:"green",fontSize:"16px",padding:"5px 10px",borderRadius:"4px" }} onClick={() => this.updateLeague(league)} disabled={loadingNew ? "disabled" : ""}>
                <strong>Refresh League</strong>
              </button>
            )
          }
        }
      }
    }
    let addNewLeaguesButton = () => {
      if (loadingNew) {
        return (
          <button className="color-white" style={{ border:"none",backgroundColor:"lightgreen",fontSize:"20px",padding:"5px 12px",borderRadius:"4px" }} disabled="disabled">
            <i className="fas fa-spinner fa-spin"></i>
          </button>
        )
      } else {
        return (
          <button className="color-white" style={{ border:"none",backgroundColor:"green",fontSize:"20px",padding:"5px 12px",borderRadius:"4px" }} onClick={this.addNewLeagues} disabled={loading ? "disabled" : ""}>
            <strong>+</strong>
          </button>
        )
      }
    }
    let unlockButton = (league) => {
      return (
        <button className="color-white" style={{ border:"none",backgroundColor:"green",fontSize:"16px",padding:"5px 10px",borderRadius:"4px" }}>
          <strong>
            <i className="fas fa-unlock" style={{ paddingRight:"4px",fontSize:"12px",top:"-2px",position:"relative" }}></i>
            Unlock League
          </strong>

          <div style={{ fontSize:"12px",fontStyle:"italic" }}>
            For only $2.99 a season!
          </div>
        </button>
      )
    }
    let archivedButton = (league) => {
      return (
        <button className="color-white" style={{ cursor:"default",border:"none",backgroundColor:"gray",fontSize:"16px",padding:"5px 10px",borderRadius:"4px" }}>
          <strong>
            League Has Been Archived
          </strong>
        </button>
      )
    }
    let checkSuperFlexTag = (superflex) => {
      if (superflex) {
        return (
          <span style={{ padding:"2px 3px",fontWeight:"bold",color:"white",borderRadius:"4px",marginLeft:"4px",fontSize:"8px",position:"relative",top:"-2px",backgroundColor:"#162635" }}>SF</span>
        )
      } else {
        return null;
      }
    }
    let checkTwoQbTag = (two_qb) => {
      if (two_qb) {
        return (
          <span style={{ padding:"2px 3px",fontWeight:"bold",color:"white",borderRadius:"4px",marginLeft:"4px",fontSize:"8px",position:"relative",top:"-2px",backgroundColor:"#162635" }}>2 QB</span>
        )
      } else {
        return null;
      }
    }
    let checkTePremiumTag = (premium) => {
      if (premium) {
        return (
          <span style={{ padding:"2px 3px",fontWeight:"bold",color:"white",borderRadius:"4px",marginLeft:"4px",fontSize:"8px",position:"relative",top:"-2px",backgroundColor:"#162635" }}>TE+</span>
        )
      } else {
        return null;
      }
    }
    let leaguesDropDown = () => {
      const styles = {
        control: (base, state) => ({
          ...base,
          height: "41px",
          boxShadow: state.isFocused ? null : null
        }),
        option: (styles, {isFocused, isSelected}) => ({
          ...styles,
          background: isFocused
              ? "#25bdff50"
              : isSelected
                  ? "#25bdff"
                  : undefined,
          zIndex: 1
        }),
        menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
        }),
        menuList: base => ({
          ...base,
          padding: 0
        })
      };

      return (
        <div className="row">
          <div className="col" style={{ paddingRight:"0" }}>
            <div className="form-group">
              <Select
                value={leagues.filter(option => option.value == league.url)}
                options={leagues.filter(option => option.value != league.url)}
                onChange={this.onChange}
                styles={styles}
                placeholder="Select League..."
              />
            </div>
          </div>

          <div className="col" style={{ maxWidth:"50px",paddingLeft:"0" }}>
            {addNewLeaguesButton()}
          </div>
        </div>
      )
    }
    let showTeamRow = (team,i) => {
      let rowNumber = i+1;

      if (league.pending) {
        return (
          <div className="accordion-item" style={{ marginBottom:"5px",border:"2px solid #5a5a5a25",borderRadius:"4px",backgroundColor:"#5a5a5a25" }}>
            <h2 className="accordion-header" id={`heading-${rowNumber}`}>
              <div style={{ padding:"6px",display:"flex",justifyContent:"space-between",height:"55px",alignItems:"center" }}>
                <div style={{ display:"flex",flex:"1",color:"#5a5a5a75" }}>
                  {teamRank(team, rowNumber)}

                  {teamName(team, true)}
                </div>
              </div>
            </h2>
          </div>
        )
      } else {
        let coreScore = Number((team.core_player_score/best_core_score)*100).toFixed(1);
        let weekAvg = (team.weekly_starter_average / team.weekly_starter_ids.length).toFixed(1)

        return (
          <div className="accordion-item" style={{ marginBottom:"5px",border:"2px solid #5a5a5a25",borderRadius:"4px" }}>
            <h2 className="accordion-header" id={`heading-${rowNumber}`}>
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${rowNumber}`} aria-expanded="false" aria-controls={`collapse-${rowNumber}`} style={{ padding:"6px",display:"flex",justifyContent:"space-between",height:"55px" }}>
                <div style={{ display:"flex",flex:"1" }}>
                  {teamRank(team, rowNumber)}

                  {teamName(team, false)}
                </div>

                <div style={{ display:"flex",fontSize:"10px" }}>
                  <div className="text-center justify-content-end" style={{ padding:"2px",fontSize:"6px",margin:"0 2px",width:"60px" }}>
                    <div style={{ fontSize:"8px",paddingTop:"2px" }}>CORE</div>

                    <div className="core-gradient" style={{ fontSize:"12px",padding:"0 2px",animationDelay:`-${coreScore}s` }}>
                      <strong>
                        {isNaN(coreScore) ? "-" : coreScore}
                        <span style={{ fontSize:"8px" }}>{isNaN(coreScore) ? "" : "%"}</span>
                      </strong>
                    </div>

                    <div style={{ padding:"2px",paddingTop:"0",fontSize:"8px" }}>
                      {team.core_player_score} / {best_core_score}
                    </div>
                  </div>

                  <div className="text-center justify-content-end" style={{ borderLeft:"1px solid #5a5a5a50",padding:"2px",fontSize:"6px",margin:"0 0 0 2px",width:"44px" }}>
                    <div style={{ fontSize:"8px",paddingTop:"2px" }}>AVG</div>

                    <div className="avg-gradient" style={{ fontSize:"12px",padding:"0 2px",animationDelay:`-${weekAvg}s` }}>
                      <strong>{weekAvg == 999.0 ? "-" : weekAvg}</strong>
                    </div>

                    <div style={{ padding:"2px",paddingTop:"0",fontSize:"8px" }}>
                      Week {current_week}
                    </div>
                  </div>
                </div>
              </button>
            </h2>

            <PlayersList
              checkable={false}
              league={league}
              players={team.players}
              rowNumber={rowNumber}
              team={team}
              picks={team.picks}
              draft_rostered_player_url={null}
            />
          </div>
        )
      }
    }

    return (
      <div className="container-fluid">
        <Header
          page={"teams"}
          urls={urls}
          lastUpdated={last_updated}
        />

        {
          league.archived ? (
            <div style={{ backgroundColor:"#5a5a5a25",marginBottom:"10px",padding:"10px",borderRadius:"4px" }}>
              {leaguesDropDown()}

              <div className="row" style={{ paddingTop:"20px" }}>
                <div className="col">
                  <div className="text-center">
                    <h1 style={{ marginBottom:"0",fontSize:"30px" }}>
                      <img style={{ width:"30px",maxHeight:"30px",margin:"0 6px",top:"-3px",position:"relative" }} src={league.logo}></img>

                      <strong>{league.name}</strong>
                    </h1>
                    <h4 style={{ fontSize:"14px" }}>{subtitle}</h4>

                    <hr></hr>

                    <div className="text-center">
                      {archivedButton(league)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            league.active ? (
              <>
                <div style={{ backgroundColor:"#5a5a5a25",marginBottom:"5px",padding:"10px",borderRadius:"4px" }}>
                  {leaguesDropDown()}

                  <div className="row" style={{ paddingTop:"20px" }}>
                    <div className="col">
                      <div className="text-center">
                        <h1 style={{ marginBottom:"0",fontSize:"30px" }}>
                          <img style={{ width:"30px",maxHeight:"30px",margin:"0 6px",top:"-3px",position:"relative" }} src={league.logo}></img>

                          <strong>{league.name}</strong>
                        </h1>
                        <h4 style={{ fontSize:"14px" }}>
                          {subtitle}

                          {checkSuperFlexTag(league.superflex)}
                          {checkTwoQbTag(league.two_qb)}
                          {checkTePremiumTag(league.te_premium)}
                        </h4>

                        <hr></hr>

                        <div className="text-center">
                          {updateLeagueButton(league)}
                        </div>

                        <div className="text-center">
                          <p style={{ marginBottom:"0",fontSize:"10px",paddingTop:"2px" }}>
                            Last Updated: {league.updated_at} ago
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <button style={{ border:"none",fontStyle:"italic",background:"none",marginBottom:"20px",color:"#00000050" }} onClick={() => this.goToLegend()}>Confused? Click to see the legend.</button>
                </div>

                <div className="row">
                  <div className="accordion">
                    <div className="accordion-item" style={{ marginBottom:"5px",border:"2px solid #5a5a5a25",borderRadius:"4px" }}>
                      <h2 className="accordion-header" id={`heading-${0}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${0}`} aria-expanded="false" aria-controls={`collapse-${0}`} style={{ padding:"6px",height:"55px" }}>
                          <div className="text-center" style={{ width:"100%" }}>
                            <div style={{ fontSize:"16px",padding:"4px" }}>
                              <strong>
                                <i className="fas fa-user-edit" style={{ paddingRight:"5px",color:"green" }}></i>

                                Best Available
                              </strong>
                            </div>
                          </div>
                        </button>
                      </h2>

                      <PlayersList
                        checkable={league.pending ? true : false}
                        league={league}
                        players={best_available}
                        rowNumber={0}
                        team={null}
                        picks={[]}
                        draft_rostered_player_url={league.draft_rostered_player_url}
                      />
                    </div>

                    {teams.map((team, i) => {
                      return (
                        showTeamRow(team,i)
                      )
                    })}
                  </div>
                </div>

                <Legend />
              </>
            ) : (
              <div style={{ backgroundColor:"#5a5a5a25",marginBottom:"10px",padding:"10px",borderRadius:"4px" }}>
                {leaguesDropDown()}

                <div className="row" style={{ paddingTop:"20px" }}>
                  <div className="col">
                    <div className="text-center">
                      <h1 style={{ marginBottom:"0",fontSize:"30px" }}>
                        <img style={{ width:"30px",maxHeight:"30px",margin:"0 6px",top:"-3px",position:"relative" }} src={league.logo}></img>

                        <strong>{league.name}</strong>
                      </h1>
                      <h4 style={{ fontSize:"14px" }}>{subtitle}</h4>

                      <hr></hr>

                      <div className="text-center">
                        {unlockButton(league)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        }

        <LoadingModal
          showModal={showModal}
        />
      </div>
    )
  }
}

function LoadingModal(props) {
  let { showModal } = props;

  let modalContent = () => {
    return (
      <>
        <h2 className="text-center">Loading your league...</h2>

        <p className="text-center" style={{ fontSize:"100px" }}>
          <i className="fas fa-spinner fa-spin"></i>
        </p>
      </>
    )
  }

  return (
    <Modal show={showModal} backdrop="static" keyboard={false} centered>
      <Modal.Body>
        {modalContent()}
      </Modal.Body>
    </Modal>
  )
}
