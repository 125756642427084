import React from "react"
import TrendingValueIcon from "../shared/icons/TrendingValueIcon";
import EarlyServiceIcon from "../shared/icons/EarlyServiceIcon";
import SleeperStatusIcon from "../shared/icons/SleeperStatusIcon";
import PlayerPosition from "../shared/icons/PlayerPosition";
import WeeklyStartIcon from "../shared/icons/WeeklyStartIcon";
import { Modal } from "react-bootstrap";

export default class PlayerRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      showModal: false,
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.setChecked = this.setChecked.bind(this)
  }

  handleClose(e) {
    this.setState({
      showModal: false
    })
  }

  handleShow(e) {
    this.setState({
      showModal: true
    })
  }

  setChecked(e) {
    if (confirm(`Temporarily mark '${e.currentTarget.getAttribute('name')}' as drafted?`)) {
      let that = this;

      const formData = new FormData();
      let data = JSON.stringify({
        player_id: e.currentTarget.getAttribute('value')
      })
      formData.append('rostered_player', data)

      fetch(this.props.draft_rostered_player_url, {
        method: 'PUT',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {

        } else {
          that.setState({
            checked: true,
            showModal: false
          })
        }
      })
    }
  }

  render () {
    let { checkable, league, player, playerIsNotCore, playerIsNotStarter, rank, totalStarters, selected } = this.props;
    let { checked, showModal } = this.state;

    let nameShortener = (name) => {
      if (name.length > 20) {
        return (
          <span>{`${name.split(" ")[0][0]}. ${name.split(" ")[1]}`}</span>
        )
      } else {
        return (
          <span>{name}</span>
        )
      }
    }
    let setRole = (role) => {
      if (role == "Starter") {
        return (
          <div style={{ position:"absolute",left:"0",top:"0",color:"orange",padding:"4px 8px",fontSize:"8px",fontWeight:"bold" }}>
            S
          </div>
        )
      } else if (role == "Backup") {
        return (
          <div style={{ position:"absolute",left:"0",top:"0",color:"black",padding:"4px 6px",fontSize:"8px" }}>
            BK
          </div>
        )
      } else {
        return (
          <div style={{ position:"absolute",left:"0",top:"0",color:"#00000050",padding:"4px 6px",fontSize:"8px" }}>
            BN
          </div>
        )
      }
    }

    if (checked) {
      return null;
    } else {
      return (
        <li key={player.rostered_player_id} className={`list-group-item d-flex hoverable`} style={{ padding:"4px 4px 4px 0",height:"47px",backgroundColor:`${playerIsNotCore ? (checked ? "#FF000015" : "white") : "#25bdff15"}` }} onClick={showModal ? null : this.handleShow}>
          {
            player.team_code ? (
              setRole(player.role)
            ) : (
              <div style={{ position:"absolute",left:"0",top:"0",color:"red",padding:"3px 6px",fontSize:"8px" }}>FA</div>
            )
          }

          <div style={{ fontSize:"20px",width:"24px",height:"37px",lineHeight:"37px" }}>
            <strong>
              <div className="text-center fs-12px" style={{ width:"16px",margin:"0 3px" }}>{rank}</div>
            </strong>
          </div>

          <div>
            <span style={{ fontSize:"14px" }}>
              <a className={`${player.reserve ? "text-decoration-line-through" : "text-decoration-none"}`} style={{ paddingRight:"4px",fontWeight:`${playerIsNotCore ? "normal" : "bold"}`,color:`${player.reserve ? "red" : "black"}` }}>
                {nameShortener(player.name)}
              </a>
            </span>

            <div style={{ fontSize:"10px",marginLeft:"-2px" }}>
              {<PlayerPosition position={player.position} selected={selected} />}
              <span> | </span>
              <span>{player.team_code ? player.team_code : "-"}</span>
              <span> | </span>
              <span>{player.age} yrs</span>
              {<EarlyServiceIcon rookie={player.rookie} sophomore={player.sophomore} junior={player.junior} />}
              {<SleeperStatusIcon taxi={player.taxi} />}
            </div>
          </div>

          <div className="d-flex text-center" style={{ right:"0",position:"absolute",fontSize:"14px",padding:"2px 0" }}>
            {
              league.pending ?
                null
              :
              <div style={{ width:"30px" }}>
                <div style={{ borderRight:"1px solid #5a5a5a50" }}>
                  <div style={{ fontSize:"7px",color:"#5a5a5a" }}>WEEK</div>
                  {
                    <WeeklyStartIcon
                      leagueSize={parseInt(league.size)}
                      notStartingOnThisTeam={playerIsNotStarter}
                      position={player.position}
                      rank={player.latest_weekly_rank}
                      superflex={league.superflex}
                      maxStartersInLeague={totalStarters}
                      offseason={league.offseason}
                    />
                  }
                </div>
              </div>
            }

            <div className="d-flex" style={{ position:"relative",width:"62px",borderRight:"1px solid #5a5a5a50" }}>
              <div style={{ width:"30px" }}>
                <div style={{ fontSize:"7px",color:"#5a5a5a" }}>DYNO</div>
                <div className="fs-12px" style={{ padding:"2px 0",margin:"0px 4px" }}>{player.dynasty_rank}</div>
              </div>

              <div className="p-0" style={{ color:"#5a5a5a50" }}>
                {<TrendingValueIcon dynastyRank={player.dynasty_rank} seasonalRank={player.seasonal_rank} size={"large"} />}
              </div>

              <div style={{ width:"30px" }}>
                <div style={{ fontSize:"7px",color:"#5a5a5a" }}>SZN</div>
                <div className="fs-12px" style={{ padding:"2px 0",margin:"0px 4px" }}>{player.seasonal_rank}</div>
              </div>
            </div>

            <div style={{ width:"40px" }}>
              <div style={{ fontSize:"7px",color:"#5a5a5a" }}>VALUE</div>
              <div className="fs-12px" style={{ padding:"2px 1px",margin:"0px 4px" }}>{player.value}</div>
            </div>
          </div>

          <PlayerModal
            onHide={this.handleClose}
            player={player}
            show={this.state.showModal}
            leaguePending={league.pending}
            setChecked={this.setChecked}
          />
        </li>
      )
    }
  }
}

function PlayerModal(props) {
  let { player, leaguePending, setChecked } = props;

  let freeAgentCheck = (player) => {
    if (player.team_code) {
      return (
        <div style={{ display:"flex",textAlign:"center",backgroundColor:"#162635",color:"white",margin:"5px 0 5px",padding:"5px",borderRadius:"4px" }}>
          <div style={{ flex:"1" }}>
            <span style={{ fontSize:"10px",fontStyle:"italic" }}>Contract </span>
            <span style={{ fontWeight:"bold",fontSize:"14px" }}>{player.contract_length} yr {player.contract_amount}</span>
          </div>

          <div style={{ flex:"1" }}>
            <span style={{ fontSize:"10px",fontStyle:"italic" }}>Exp </span>
            <span style={{ fontWeight:"bold",fontSize:"14px" }}>{player.contract_expiration}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ display:"flex",textAlign:"center",backgroundColor:"red",color:"white",margin:"5px 0 5px",padding:"5px",borderRadius:"4px" }}>
          <div style={{ flex:"1" }}>
            <span style={{ fontWeight:"bold",fontSize:"14px" }}>Free Agent</span>
          </div>
        </div>
      )
    }
  }

  let modalContent = () => {
    return (
      <div>
        <div style={{ display:"flex",position:"relative" }}>
          <div>
            <img src={player.logo} style={{ width:"30px",borderRadius:"100px" }}></img>
          </div>

          <div style={{ fontSize:"20px",fontWeight:"bold",padding:"0 10px",lineHeight:"1.2" }}>
            {player.name}

            {
              player.team_code && player.role ? (
                <div style={{ fontSize:"10px",fontStyle:"italic",color:"#00000075" }}>{player.team_code} {player.role}</div>
              ) : (
                null
              )
            }
          </div>

          <div style={{ position:"absolute",top:"0",right:"0" }} onClick={() => props.onHide()}>
            <i style={{ fontSize:"24px",color:"red" }} className="fas fa-times-circle hoverable"></i>
          </div>
        </div>

        <div style={{ display:"flex",textAlign:"center",backgroundColor:"#162635",color:"white",margin:"10px 0 5px",padding:"5px",borderRadius:"4px" }}>
          <div style={{ flex:"1" }}>
            <span style={{ fontSize:"10px",fontStyle:"italic" }}>Pos </span>
            <span style={{ fontWeight:"bold",fontSize:"14px" }}>{player.position}</span>
          </div>

          <div style={{ flex:"1" }}>
            <span style={{ fontSize:"10px",fontStyle:"italic" }}>Age </span>
            <span style={{ fontWeight:"bold",fontSize:"14px" }}>{player.age}</span>
          </div>

          <div style={{ flex:"1" }}>
            <span style={{ fontSize:"10px",fontStyle:"italic" }}>Exp </span>
            <span style={{ fontWeight:"bold",fontSize:"14px" }}>{player.years_exp}</span>
          </div>
        </div>

        {freeAgentCheck(player)}

        <div style={{ padding:"5px 10px 10px",backgroundColor:"#fbb03b",borderRadius:"4px" }}>
          <div style={{ fontStyle:"italic",fontWeight:"bold",paddingBottom:"5px",paddingLeft:"3px" }}>
            Dynasty Rankings ({player.position}{player.dynasty_rank})
          </div>

          <div style={{ maxHeight:"160px",overflow:"auto" }}>
            {player.dynasty_rankings.map(ranking => {
              return (
                <div style={{ display:"flex",justifyContent:"space-between",padding:"5px 10px",marginBottom:"3px",borderRadius:"4px",color:`${ranking.display == "N/A" ? "#16263550" : "#162635"}`,textAlign:"center",width:"100%",backgroundColor:`${ranking.display == "N/A" ? "#e9dcd5" : "#fff"}` }}>
                  <div style={{ fontSize:"14px",fontStyle:"italic",position:"relative" }}>
                    <img style={{ width:"16px",height:"16px",top:"3px",position:"absolute",filter:`grayscale(${ranking.display == "N/A" ? 1 : 0})` }} src={ranking.icon}></img>
                    <span style={{ paddingLeft:"20px" }}>
                      {ranking.name}

                      {
                        ranking.published_at ? (
                          <div style={{ fontSize:"8px",textAlign:"left",color:"#00000080" }}>({ranking.published_at})</div>
                        ) : (
                          <></>
                        )
                      }
                    </span>
                  </div>
                  <div style={{ fontSize:"16px",fontWeight:"bold" }}>{ranking.display}</div>
                </div>
              )
            })}
          </div>
        </div>

        <div style={{ padding:"5px 10px 10px",backgroundColor:"#25bdff",borderRadius:"4px",marginTop:"10px" }}>
          <div style={{ fontStyle:"italic",fontWeight:"bold",paddingBottom:"5px",paddingLeft:"3px" }}>
            Redraft Rankings ({player.position}{player.seasonal_rank})
          </div>

          <div style={{ maxHeight:"160px",overflow:"auto" }}>
            {player.redraft_rankings.map(ranking => {
              return (
                <div style={{ display:"flex",justifyContent:"space-between",padding:"5px 10px",marginBottom:"3px",borderRadius:"4px",color:`${ranking.display == "N/A" ? "#16263550" : "#162635"}`,textAlign:"center",width:"100%",backgroundColor:`${ranking.display == "N/A" ? "#e9dcd5" : "#fff"}` }}>
                  <div style={{ fontSize:"14px",fontStyle:"italic",position:"relative" }}>
                    <img style={{ width:"16px",height:"16px",top:"3px",position:"absolute",filter:`grayscale(${ranking.display == "N/A" ? 1 : 0})` }} src={ranking.icon}></img>
                    <span style={{ paddingLeft:"20px" }}>
                      {ranking.name}

                      {
                        ranking.published_at ? (
                          <div style={{ fontSize:"8px",textAlign:"left",color:"#00000080" }}>({ranking.published_at})</div>
                        ) : (
                          <></>
                        )
                      }
                    </span>
                  </div>
                  <div style={{ fontSize:"16px",fontWeight:"bold" }}>{ranking.display}</div>
                </div>
              )
            })}
          </div>
        </div>

        {
          leaguePending ? (
            <div className="hoverable" onClick={setChecked} style={{ marginTop:"10px",backgroundColor:"red",padding:"10px",borderRadius:"4px",textAlign:"center",fontWeight:"bold",fontSize:"20px",color:"white" }} value={player.rostered_player_id} name={player.name}>
              <i className="fas fa-user-minus" style={{ paddingRight:"10px" }}></i>
              Mark as Drafted
            </div>
          ) : (
            null
          )
        }
      </div>
    )
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onHide} centered>
        <div style={{ zIndex:"-1",position:"fixed",top:"0",left:"0",width:"100vw",height:"100vh",backgroundColor:"#00000050" }}></div>

        <Modal.Body>
          {modalContent()}
        </Modal.Body>
      </Modal>
    </>
  )
}
