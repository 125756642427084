import React from "react"
import Header from "../shared/Header";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.logout = this.logout.bind(this)
  }

  logout() {
    fetch(this.props.logout_url, {
      method: 'PUT',
      headers: {},
      body: {}
    }).then(response => {
      return response.json();
    }).then((_) => {
      window.location.reload();
    })
  }

  render () {
    let {
      last_updated,
      urls,
      user,
    } = this.props;
    let {} = this.state;

    return (
      <div className="container-fluid">
        <Header
          page={"profile"}
          urls={urls}
          lastUpdated={last_updated}
        />

        <div style={{ backgroundColor:"#5a5a5a25",marginBottom:"10px",padding:"10px",borderRadius:"4px",top:"20px",position:"relative" }}>
          <div className="row">
            <div className="col" style={{ textAlign:"center" }}>
              <h3>
                Welcome
                <strong> {user.username}!</strong>
              </h3>

              <div style={{ margin:"20px" }}>
                <button className="color-white" style={{ border:"none",backgroundColor:"red",fontSize:"16px",padding:"5px 10px",borderRadius:"4px" }} onClick={this.logout}>
                  <strong>Logout</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
